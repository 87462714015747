#modal-window {
  .modal-body, .modal-body p, h1, h2, h3, h4, h5, h6, li {
    color: $color-base;
  }

  button.btn {
    @include butt();
    &.btn-secondary {
      background-color: $color-body-grey;
      &:hover {
        background-color: $color-accent;
      }
    }

  }
}


.modal-data {
  display: none;
}


.modal {
  button.close {
    &, span {
      background: transparent;
    }
  }

  img.headshot {
    max-width: 250px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  h1, h2, h3, h4 {
    text-align: center;
  }
}
