section.grid {
  img {
    display: block;
    margin: 0;
    padding: 0;
  }

  .row.skinny-row {
    //background: black;
    padding: 0;
    margin: 0 -25px;
  }
}



section.grid.benefits {
  @media handheld, only screen and (max-width: 768px) {
    background: transparent !important;
  }
  text-align: center;
  .row {
    border-top: 1px solid $color-hr;

    padding: 0;
    .grid-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding: 50px;
      border-bottom: 1px solid $color-hr;
      border-left: 1px solid $color-hr;
    }

    .grid-item:nth-child(2n-1) {
      border-left: none;
      @media handheld, only screen and (max-width: 768px) {
        border-left: 1px solid $color-hr;
      }
      @media handheld, only screen and (max-width: 991px) {
        border-left: 1px solid $color-hr;
      }
    }
    .grid-item:nth-child(2n-2) {
      border-right: none;
    }
    .grid-item:last-child {
      border-right: 1px solid $color-hr;
      position:relative;
      left: 1px;
    }

    @media handheld, only screen and (max-width: 991px) {
      .grid-item:nth-child(2n-1) {
        //border-left: none;
      }
    }
    @media handheld, only screen and (max-width: 768px) {
      .grid-item {
        border-left: none !important;
        border-right: none !important;
      }
    }
  }
}


section.grid.dividers {
  @media handheld, only screen and (max-width: 768px) {
    background: transparent !important;
  }
  .row {
    border-top: 1px solid $color-hr;
    padding: 0;
    .grid-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding: 50px;
      border-bottom: 1px solid $color-hr;
      border-left: 1px solid $color-hr;
    }

    .grid-item:nth-child(3n-2) {
      border-left: none;
      @media handheld, only screen and (max-width: 768px) {
        border-left: 1px solid $color-hr;
      }
      @media handheld, only screen and (max-width: 991px) {
        border-left: 1px solid $color-hr;
      }
    }
    .grid-item:nth-child(3n-3) {
      border-right: none;
    }
    .grid-item:last-child {
      border-right: 1px solid $color-hr;
      position:relative;
      left: 1px;
    }

    @media handheld, only screen and (max-width: 991px) {
      .grid-item:nth-child(2n-1) {
        //border-left: none;
      }
    }
    @media handheld, only screen and (max-width: 768px) {
      .grid-item {
        border-left: none !important;
        border-right: none !important;
      }
    }
  }
}
