
$color-footer-text: #A4B2BC;


.footer.footer {
  padding: 50px 0;
  text-align: left;
  background: $color-base;

  //background: linear-gradient(-180deg, lighten($color-base, 3%), $color-base, $color-base);
  //background: $color-base;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  a {
    color: $color-footer-text;
    border-color: $color-accent;
    &:hover {
      color: #fff !important;
      border-bottom: 1px solid #fff;
    }
  }


  a.butt {
    color: $color-footer-text;
    background: $color-base;
  }

  p.address {
    line-height: 170%;
    a {
      border: none;
    }
    margin-bottom: 10px;
  }

  .left {
    text-align: left;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 180px;
      margin: 0 auto 30px;
    }
  }

  .right-center {
    text-align: right;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &, p, ul li {
    font-size: 16px;
  }
  &, p, ul li, h1,h2,h3,h4 {
    color: $color-footer-text;
  }


  h3 {
    font-size: 25px;
    //letter-spacing: .1em;
    //text-transform: uppercase;
    margin-bottom: 25px;
    font-weight: 500;
    color: $color-footer-text;
  }


  p {
    margin: 0;
  }

  nav {
    display: block;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    ul {
      &, li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      li {
        padding: 2px 0;
      }
    }
  }





  .sn {
    //@include flex-justify-end;
    //text-align: right;
    padding: 25px 0;

    //@include media-breakpoint-down(sm) {
      margin-top: 10px;
      @include flex-justify-center;
    //}
  }


  .legal {
    p {
     color: #85959E;
     text-align: center;
     padding-top: 60px;
     letter-spacing: .15em;
     font-size: 12px;
     text-transform: uppercase;
    }
  }
}




.footer-mark {
  svg {
    .st0{fill:$color-accent;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:$color-accent-secondary;stroke-miterlimit:10;}

    #ring1 {
      transform-origin: center center;
      -moz-transform-origin: center center;
      -webkit-animation:spin 25s linear infinite;
      -moz-animation:spin 25s linear infinite;
      animation:spin 25s linear infinite;
    }
    #ring2 {
      transform-origin: center center;
      -moz-transform-origin: center center;
      -webkit-animation:spin 12s linear infinite;
      -moz-animation:spin 12s linear infinite;
      animation:spin 12s linear infinite;
      .st1{stroke:$color-accent;}
    }
    #ring3 {
      transform-origin: center center;
      -moz-transform-origin: center center;
      -webkit-animation:spin 22s linear infinite;
      -moz-animation:spin 22s linear infinite;
      animation:spin 22s linear infinite;
    }
    #ring4 {
      transform-origin: center center;
      -moz-transform-origin: center center;
      -webkit-animation:spin 40s linear infinite;
      -moz-animation:spin 40s linear infinite;
      animation:spin 40s linear infinite;

      .st1{stroke:$color-accent;}
    }
  }
}




@-moz-keyframes spin { 100% {
  -moz-transform: rotate(360deg);
} }
@-webkit-keyframes spin { 100% {
  -webkit-transform: rotate(360deg);
} }
@keyframes spin { 100% {
  -webkit-transform: rotate(360deg); transform:rotate(360deg);
} }
