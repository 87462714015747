




html body .gform_wrapper .gform_body .gform_fields {
  input {
    @include transition();
  }
  input[type=text], textarea {
    background: #EFEEED;
    @include inner-shadow(none);
    @include box-shadow(none);
    border: 2px solid #EFEEED;
    border-bottom: 2px solid $color-hr;
    padding: 15px !important;
  }
  input:focus,
  textarea:focus,
  select:focus,
  {
    border-color: $color-accent !important;
    outline: none;
    @include transition();
  }

  input[type=checkbox]:focus,
  {
    border-color: #4F9AB7 !important;
    box-shadow: none !important;
    outline: none;
    @include transition();
  }

  .gfield {
    padding: 20px 0;
    margin: 20px 0;
  }


  label.gfield_label {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: center;
  }


  .gfield_checkbox {
		li {
			position: relative;
      line-height: 100%;
			padding-left:40px;
			label {
        padding-left: 30px;
				&:after {
					content:"";
					background:#EFEEED;
          border: 2px solid $color-hr;
					width:24px;
					height:24px;
					display:block;
					position: absolute;
					left:0;
					top:0;
					border-radius:3px;
					z-index: 0;
          @include transition();
				}
				&:before {
					content:"";
					display:block;
				    position: absolute;
				    width: 6px;
					height: 15px;
				    border: solid #fff;
				    border-width: 0 3px 3px 0;
			    	left: 9px;
					top: 4px;
					transform: rotate(45deg);
					opacity:0;
					z-index:1;
					transition:all 100ms;
				}
			}
		}

	}

	input[type="checkbox"] {
		opacity:0;
		outline:none;
		z-index:100;
		width:27px;
		height:27px;
		top:0;
		left:0;
		position: absolute;
		appearance: none;
		cursor: pointer;
    + label {
      cursor: pointer;
    }
		&:checked + label {
      font-weight: 400;
			&:before {
				opacity:1;
				top: 3px;
			}
      &:after {
        content:"";
        background: $color-accent;
        border: 2px solid $color-accent;
      }
		}
	}
}
