
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 400% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 400% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}


section.page-title {
  background: lighten($color-base, 2%) url("#{$images}/loading.gif") center center no-repeat;
  background-size: 60px 60px;
  background-position: 50% 50%;
  clear: both;
  -webkit-transition:background-position .2s ease;
	-moz-transition:background-position .2s ease;
	-o-transition:background-position .2s ease;
	transition:background-position .2s ease;

  .overlay {
    box-sizing: border-box;
    padding: 30px 30px;
    height: 450px;
    min-height: 450px;
    text-align: center;
    //background: $color-overlay-rgba;
    background: transparent;
    @include media-breakpoint-down(sm) {
      min-height: 250px;
      height: 250px;
      padding-top: 80px;
    }
    &.no-content {
      background: transparent;
    }

    @include flex-it;
    @include flex-align-item-center;

    .flex-container {
      margin: 0 auto;
      flex-grow: 1;
    }

    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }

    p {
      max-width: 550px;
      margin: 20px auto 50px;
      display: block;
    }

    h1 {
      margin: 0 auto;
      padding: 0;
      @include media-breakpoint-down(sm) {
        //font-size: 29px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
      html.wf-active & {
        //font-size: 19px;
        //line-height: 100%;
      }
    }

  }

  &.content-bottom .overlay {
    @include flex-align-item-end;
  }
  &.content-top .overlay {
    @include flex-align-item-start;
  }
}


html {
  section.page-title {
      &.inplace {
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-animation: none;
      	-moz-animation: none;
      	animation: none;

        .overlay {
          background: linear-gradient(-180deg, rgba(22,29,38,.7), rgba(22,29,38,.2));
        }

        &.img-bottom {
          background-position: center bottom;
        }
        &.img-top {
          background-position: center top;
        }
      }
  }
}


body.home {
  section.page-title {
    .overlay {
      height: 90vh;
      min-height: 600px;
      max-height: 900px;
    }

    h1 {
      max-width: 1000px;
    }
  }
}




//
// Hero video
//
.video-overlay, .hero-video {
  height: 550px;
  height: 100vh;
  @media handheld, only screen and (max-width: 900px) {
    height: 500px;
  }
  @media handheld, only screen and (max-width: 600px) {
    height: 400px;
  }
}


.video-overlay {
  position: absolute;
  @include flex-it;
  @include flex-align-item-center;
  background: linear-gradient(-180deg, rgba(22,29,38,.85), rgba(22,29,38,.85), rgba(22,29,38,1));
  text-align: center;
  width: 100%;
  z-index: 10;

  h1,h2,h3,h4,h5,p {
    color: #fff;
  }
}
.hero-video {
  background-position: center center;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;

  video {
    //position: absolute;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: margin 0.3s ease;
    @media handheld, only screen and (min-width: 1070px) {
      height: auto;
      width: 100%;
    }
    @media handheld, only screen and (max-width: 768px) {
      //display: none;
    }
  }
}
