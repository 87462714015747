.faqs {
  .faq {
    border-bottom: 1px solid $color-hr !important;
    @include transition();
    h3 {
      margin: 0 0 10px 0;
      cursor: pointer;
      @include transition();
      line-height: 130%;
      font-size: 20px;
      &:hover {
        color: $color-accent-secondary;
      }
    }
    h3::before {
      padding: 0 0 15px 15px;
    }

    & > a {
      padding: 20px 15px 10px;
      display: block;
      border: none;
      &:hover {
        background: #EFEEED;
      }
    }

    &.showing {
      background: #EFEEED;
    }

    h3::before {
      float: right;
      content: "+";
      display: block;
      line-height: 100%;
      font-weight: 600;
      color: #fff;
      width: 30px;
      height: 30px;
      background: $color-accent;
      text-align: center;
      box-sizing: border-box;
      padding: 7px 5px;
      font-size: 15px;
      margin-left: 15px;
      line-height: 100%;
      @include rounded(15px);
      @include transition();
    }
    &.showing h3::before {
      content: "-";
      //padding: 5px;
      background: $color-accent-secondary;
    }

    a:hover {
      h3:before {
        background: $color-accent-secondary;
      }
    }
    .answer {
      transition: none !important;
      margin: 0 15px 30px;
      //transition: none;
      p:last-child {
        margin: 0;
      }

      &.collapsing {
        left: 0;
        height: auto;
      }
    }
  }
  .faq:last-child {
    //border: none;
  }
}
