section.pghead {
  .row {
    div.col {
      background: url("#{$images}/grey-line.png") center center repeat-x;
      background-size: auto 1px;
      padding: 0;
    }
    padding-left: 30px;
    padding-right: 30px;
  }
  h1, h2 {
    background: #fff;
    margin: 0;
    padding: 0;
    line-height: 100%;
    display: inline-block;
    padding: 0 20px 0 0;
  }
  h1 {
    font-size: 30px;
  }
}


.page-heading {
  margin: 50px 0;

  .col {
    @include flex-it;
    @include flex-align-item-center;
    @include flex-justify-center;

    h1 {
      font-family: $font-menu;
      font-size: 17px;

      letter-spacing: 0.08em;
      margin: 0;
      color: $color-base;

      html.wf-active & {
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }
    }
    span {
      height: 50px;
      width: 1px;
      border-left: 1px solid $color-hr;
      margin: 0 20px;
    }
    h2 {
      font-size: 34px;
      margin: 0;
      line-height: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
      span {
        width: 50px;
        height: 1px;
        border: 0;
        border-top: 1px solid $color-hr;
        margin: 20px auto;
        display: block
      }
    }
  }
}



section.page-title {
  background: $color-base url("#{$images}/loading.gif") center center no-repeat;
  background-size: 60px 60px;
  clear: both;

  .overlay {
    height: 450px;
    text-align: center;
    &.no-content {
      background: transparent;
    }
    @include flex-it;
    @include flex-align-item-center;

    .flex-container {
      margin: 0 auto;
    }

    @media handheld, only screen and (max-width: 768px) {

    }


    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }


    h1 {
      margin: 0 25px;
      padding: 0;
    }

    p {
      margin: 15px 25px 0;
    }
  }
}
html {
  section.page-title {
      &.inplace {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

      }
  }
}



section.stylized-img {
  img {
    width: 100%;
    height: auto;
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
}



html body section.fact {
  padding: 150px 0;
  //background: linear-gradient(90deg, rgba(28,110,140,1), rgba(133,99,78, 1), rgba(28,110,140,1), rgba(133,99,78, 1));
  //background-position: 0 0;
  //background-size: 500% 100%;
//  -webkit-animation: Gradient 60s ease infinite;
  //-moz-animation: Gradient 60s ease infinite;
  //animation: Gradient 60s ease infinite;
  background: $color-accent;
  .row {
    div[class*="col-"]:first-child {
      text-align: right;
      h2 {
        //max-width: 300px;
        display: inline-block;
        font-size: 60px;
      }
    }
  }
  strong {
    background: rgba(0,0,0,0.2);
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .1em;
    padding: 4px;
  }
  h3, p {
    margin: 0;
    color: rgba(255,255,255,.75);
  }
}


section.process {
  text-align: center;
}
