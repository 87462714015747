
@mixin butt {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-weight: 500;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  line-height: 100%;
  background-color: transparent;
  color: $color-accent;
  @include rounded(0);
  text-transform: uppercase;
  letter-spacing: .2em;
  padding: 20px 20px;
  border: 1px solid $color-accent !important;
  @include hover-border();

  //border-image-slice: 1 !important;
  //border-width: 2px !important;
  //border-image: linear-gradient(to left, #00C853, #B2FF59) !important;
}

  .butt,
  a.butt:active,
  a.butt:hover,
  a.butt:link,
  a.butt:visited,
  button,
  input.button {
    @include butt;
  }

@mixin butt-hover {
  //background-color: $color-accent-secondary;
  color: $color-accent-secondary !important;
  border-color: transparent !important;
}

@mixin disabled {
  background-color: rgba(0, 0, 0, .2);
  color: #fff;
}

a:hover.butt,
button:hover,
input.button:hover {
  @include butt-hover;
}


input[type=submit] {
  @include butt();
  @include transition();
  &:hover {
    @include butt-hover();
  }

  &:disabled {
    @include disabled();
    cursor: default;
  }
}
