
.testimonials.full-list {
  .testimonial {
    margin-bottom: 50px;
    //border: 1px solid $color-hr;


    p {
      //@include gradient-corner($color-accent, $color-accent, lighten($color-accent, 12%));
      background: $color-accent;
      color: #fff;
      padding: 15px;
      @include rounded(5px);


    }

    .author {
      font-family: $font-headline;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -26px;
        left: 20px;
        background: none;
        border: none;
        line-height: 100%;
        margin: 0;
        min-height: auto;
        padding: 0;
        height: auto;
        border-top: 9px solid $color-accent;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        @include transition();
      }
    }

    @include rounded(3px);
    padding: 20px;
    background-color: #fff;
    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }
}


.testimonial-slider {
  div.flexslider {
  background: rgba(0, 0, 0, .02);
  //@include gradient-corner($color-base, rgba(0,143,193,.7), rgba(247,217,62,.7));
  //@include gradient-corner($color-base, rgba(0,143,193,.95), rgba(0,143,193,.7));
  background: $color-accent;
  margin-bottom: -10px;
  display: block;


  ul.slides {
    li {
      padding: 30px 0 50px;
      .overlay {
        text-align: center;
        min-height: 300px;
        @include flex-it;
        @include flex-align-item-center;
      }
      .content {
        width: 65%;
        margin: 0 auto;
        &, p, h1, h2, h3, h4 {
          color: #fff;
        }
        &, p {
          font-size: 22px;
        }
        @media handheld, only screen and (max-width: 768px) {
          width: auto;
          margin: 0 25px;
        }
      }
    }
  }
}

}
