$caret-width-base: 4px;
$caret-width-large: 5px;

/* =================================================
     Header
   ============================================== */
.header {
  padding: 15px 0;
  background-color: $color-accent;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
  box-sizing: border-box;
  //height: 103px;
  @include transition();
  @media handheld, only screen and (max-width: 992px) {
    padding: 10px 0;
    //height: 80px;
  }

  html.hero & {
    background-color: transparent;
  }
  body.home & {
    //background-color: transparent;
  }
  html &.scrollnav {
    background-color: $color-base;
    background-color: rgba(145, 105, 78,.97);
  }
  body.admin-bar & {
    top: 30px;
  }
  a {
    border: none;
  }

  a.cart {
    display: inline-block;
  }
  a.logo {
    display: block;
    border: none !important;
    img {
      width: 140px;
      height: auto;
      display: block;
    }

    @media handheld, only screen and (max-width: 992px) {
      width: 100px;
      img {
        width: 120px;
        height: auto;
      }
    }
  }
}

div.wrap {
  clear: both;
}
.wrap {
  clear: both;
  padding-top: 95px !important;
  box-sizing: border-box;
  min-height: 94.5vh;

  html.hero & {
    padding-top: 0 !important;
  }

  /* Based on the height of the menu */
  @media handheld, only screen and (max-width: 1000px) {
    //padding-top: 75px !important;
    padding-top: 70px;
    /* Based on the height of the menu */
  }
}




.header.top {
  background-color: #fff;
}

.headroom {
  @include transition();
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}
@media handheld, only screen and (max-width: 1000px) {
  .headroom--pinned {
    transform: none;
  }

  .headroom--unpinned {
    transform: none;
  }
}
// Main navigation.
