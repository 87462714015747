
/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@mixin hvr-ripple-out() {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }

  &:hover:before, &:focus:before, &:active:before {
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    border: $color-accent-secondary solid 1px;
  }
}






.sn {
  font-size: 0px;
  @include flex-it;
  @include flex-align-item-center;

  a {
    background: url("#{$images}/sn-sprites.png") center center repeat-y;
    background-size: 24px auto;
    width: 44px;
    height: 44px;
    margin: 0;
    display: inline-block;
    border: 0;
    @include transition(.2s);
    @include opacity(1);
    text-decoration: none;
    margin: 0 6px;
    background: $color-base;
    //@include rounded(22px);

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    svg {
      display: none;
    }

    //@include hover-border();
    @include hvr-ripple-out();

    .svg & {
      @include flex-it;
      @include flex-align-item-center;
      @include flex-justify-center;

      svg {
        width: 100%;
        height: auto;
        max-height: 21px;
        max-width: 21px;
        display: block;

        .st0 {
          @include transition();
          fill: #fff;
        }
      }

      &.fb {
        svg {
          //width: 8px;
          //height: 13px;
        }
      }

      &:hover {
        background: $color-accent-secondary;
        border: none;
        svg {
          .st0 {
            fill: #fff;
          }
        }
      }
    }
  }

  &.on-color {
    a {
      .svg & {
        svg {
          .st0 {
            fill: #fff;
          }
        }
      }
    }
  }
}

.sn a.twitter {
  background-position: 6px 10px;
}

.sn a.fb {
  background-position: 6px -83px;
}

.sn a.google {
  background-position: 6px -173px;
}

.sn a.instagram {
  background-position: 6px -263px;
}

.sn a:hover {
  @include opacity(1);
}
