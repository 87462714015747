

.entry-content {
  section:first-child {
    p:first-child {
      &::first-letter {
        font-family: $font-headline;
        font-size: 70px;
        height: 70px;
        line-height: 90%;
        float: left;
        color: $color-text;
        margin-right: 5px;
        margin-top: 4px;
      }
    }
  }
}


article.post {
  time, p.author {
    display: inline-block;
    font-size: 14px;
    color: $color-light-text;
  }
  span.del {
    color: $color-light-text;
    @include opacity(.5);
  }

  header {
    padding: 15px;
    text-align: center;
  }

}



section.blog-filters {
  margin-bottom: 50px;
  .cats {
    select {
      width: 100%;
    }
  }
  .search-form {
    label {
      padding: 0;
      margin: 0;
      input.search-field {
        padding: 8px !important;
        font-size: 16px;
        margin: 0;
        width: auto !important;
        display: block !important;
        box-sizing: border-box;
      }
    }
    .search-submit {
      display: none;
    }
  }
}


.posts-cat-focus {
  h3 {
    text-transform: none;
    letter-spacing: 0;
    font-size: 18px;
  }
  img.thumb {
    width: 100%;
    margin-bottom: 15px;
  }
  .row {
    .row {
      margin: 0 -25px;
      padding: 0;
    }
  }
}



.post-list {
  .row {
    .col-md-6 {
      border-right: 1px solid $color-hr;
      border-bottom: 1px solid $color-hr;
    }

    @include media-breakpoint-up(lg) {
      .col-md-6 {
        border-right: 1px solid $color-hr;
        border-bottom: 1px solid $color-hr;
      }
      .col-md-6:nth-child(3n) {
        border-right: none;
      }
      .col-md-6:nth-last-child(-n+3) {
        border-bottom: none;
      }
    }

    @include media-breakpoint-down(md) {
      .col-md-6 {
        border-right: 1px solid $color-hr;
        border-bottom: 1px solid $color-hr;
      }
      .col-md-6:nth-child(2n) {
        border-right: none;
      }
      .col-md-6:nth-last-child(-n+2) {
        border-bottom: none;
      }
    }
    @include media-breakpoint-down(sm) {
      .col-md-6 {
        border-right: 1px solid $color-hr;
        border-bottom: 1px solid $color-hr;
      }
      .col-md-6 {
        border-right: none;
      }
      .col-md-6:last-child {
        border-bottom: none;
      }
    }
  }
  article.post {
    heght: 100%;
    display: block;
    //min-height: 300px;
    @media handheld, only screen and (min-width: 1450px) {
      //min-height: 250px;
    }
    @include media-breakpoint-down(md) {
      //min-height: 270px;
    }



    header, .entry-summary {
      //padding: 0 15px;
    }

    header {
      min-height: 70px;
      padding-top: 15px;
      h2.entry-title {
        font-size: 19px;
        line-height: 150%;
        margin: 0 0 10px 0;
        html.wf-active & {
          font-size: 25px;
          line-height: 120%;
        }
      }
    }
    .entry-summary {
      padding-bottom: 15px;
    }

  }
}


.post-list {
  .row {
    padding: 0;
    .col-md-6 {
      border: 0;
    }
  }
  .post-block {
    padding: 0;
    background-size: cover;
    background-position: center center;
    height: 300px;
    text-align: left;
      a.article {
        height: 100%;
        background: rgba(0, 0, 0, .3);
        padding: 10px 10px 10px;
        margin: 0;
        display: block;
        @include flex-it;
        @include flex-align-item-end;
        header {
          text-align: left;
          b {
            font-weight: 400;
            font-size: 17px;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, .1);
        }
        &, h2 {
          color: #fff;
        }
        h2 {
          margin: 0;
          padding: 0;
        }
      }
    }
  }



//
// Social buttons
//
.entry-share {
  ul.entry-share-btns {
    li.entry-share-btn {
      a {
        background: $color-accent-secondary;
        color: #fff;
        &:hover {
          background: $color-accent;
        }
      }
    }
  }
}



section.social-media-share {
  margin-top: 100px;
  margin-bottom: 80px;
  text-align: center;
}





//
// Pager
//
.pager {
  background: darken(#F9FBFD, 2%);
  .row {
    padding: 0;
    nav.posts-navigation {
      .nav-links {
        width: 100%;
        @include flex-it;
        @include flex-align-item-center;
        div {
          width: 50%;
          a {
            display: block;
            padding: 50px;
            background: $color-base;
            text-align: center;
            font-family: $font-menu;
            color: #fff;
            font-size: 22px;
            &:hover {
              background: $color-accent;
            }
            @include media-breakpoint-down(sm) {
              font-size: 19px;
              padding: 30px 20px;
            }
          }
        }
      }
    }
  }
}
