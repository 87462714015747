@import "grid.scss";
@import "slider.scss";
@import "hero.scss";


.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

section.emphasize {
  p {
    font-size: 22px;
    font-family: $font-headline;
    line-height: 180%;
    color: $color-accent;
  }
  &.elarger {
    p {
      font-size: 30px;
    }
  }
}


section.branded {
  background: $color-accent;;
  padding: 50px 0;

  //background: linear-gradient(135deg, $color-accent, $color-accent, #0D7C74);
  //background: #d9d8d6;
  //background: url("#{$images}/pattern-bg.png") center center repeat;
  //background-size: 20px auto;

  &, p, h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
    line-height: 110%;
  }
  p {
    margin-top: 15px;
  }
}


section.branded-alternate {
  background: #F9F6EF;
  padding: 50px 0;
}


section.branded-variation {
  background: lighten($color-accent, 4%);
  background: url("#{$images}/wave-pattern.png") center center repeat;
  background-size: 120px auto;
  padding: 50px 0;

  &, p, h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }

  a.butt {
    //color: #fff;
    border-color: rgba(14,75,98,.5);
    color: $color-base;
    &:hover {
      color: #fff;
      border-color: $color-accent-secondary;
    }
  }
}



section.has-bg-img {
  background: $color-base;
  background-size: 60px 60px;
  clear: both;

  .overlay {
    padding: 80px 0;
    background: $color-overlay-rgba;
  }
  &, p, h1, h2, h3, h4, h5, h6, li {
    color: #fff;
  }
  a.butt {
    color: #fff;
  }
}
html {
  section.has-bg-img {
      &.loaded {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
  }
}

section.margin-top-sm {
  margin-top: 20px;
}
section.margin-top-md {
  margin-top: 50px;
}
section.margin-top-lg {
  margin-top: 100px;
}
section.margin-top-none {
  //margin-top: 0;
}


section.margin-bot-sm {
  margin-bottom: 20px;
}
section.margin-bot-md {
  margin-bottom: 50px;
}
section.margin-bot-lg {
  margin-bottom: 100px;
}
section.margin-bot-none {
  //margin-bottom: 0;
}


section {
  img.aligncenter {
    display: block;
    margin: 0 auto;
  }
  img.alignright {
    display: block;
    margin: 0 0 0 auto;
  }
}



section.two-col.divider-lines {
  .row {
    div[class*="col-"] {
      border-right: 1px solid $color-hr;

      &:last-child {
        border: none;
      }

      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
  }
}


// Facet
div.facetwp-facet {
  text-align: center;
  //display: inline-block;
  //margin: 0 auto;
  select {
    margin: 0 auto;
  }
}



// Two column minimize the image size.
.two-col.imgms {
  img {
    display: block;
    margin: 0 auto;
    max-width: 400px;
  }
}
