$color-base: #161D26;
$color-text: #414D5B;
$color-subtle: rgba(0, 0, 0, .4);
$color-accent: #91694E;
$color-admin-accent: #91694E;
$color-headings: $color-base;
$color-accent-secondary: #1C6E8C;
$color-accent-third: #325E73;
$color-overlay-rgba: rgba(20,24,30,.7);
$color-neutral: #A0A0A0;
$color-body-grey: #B5B5B5;
$color-light-text: #B5B5B5;
$color-light-text-forms: rgba(0, 0, 0, .55);
$color-light: #EFEFEF;
$color-grey: #AEB8C4;
$color-subtle-background: #F7F5F2;
$color-subtle-background-secondary: #EBF3F7;
$color-on-base: #889998;
$color-grey-on-base: #637575;
$color-light-box: #F7F7F7;
$color-hr: rgba(0,0,0,.2);
$color-hamburger: $color-base;
$color-hamburger-menu-open: #fff;

$color-table-heading-bg: #CCDAE2;




$font-sans:	BrandonTextWeb-Regular, sans-serif;
$font-serif: BrandonTextWeb-Regular, sans-serif;
$font-body: BrandonTextWeb-Regular, sans-serif;
//$font-headline: "futura-pt-condensed", "futura-pt", sans-serif;
$font-headline: DomaineDisplayWeb-Medium, serif;
//$font-menu: "futura-pt-condensed", "futura-pt", sans-serif;
$font-menu: BrandonTextWeb-Regular, sans-serif;
$images: "../../assets/images";
