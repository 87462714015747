

.map-markers {
  display: none;
}


.acf-map {
  width: 100%;
	height: 600px;
	border: 0;
	margin: 0;
  img {
     max-width: inherit !important;
  }

  &, p, h1, h2, h3, h4, h5 {
    color: $color-base;
  }
  a {
    color: $color-base;
    border-bottom: 1px solid $color-hr;
  }
}

.location {
  p {
    line-height: 140%;
    margin-bottom: 15px;
  }
}
.locations-grid {

  .location {
    margin-top: 20px;
    margin-bottom: 20px;

  }

  .box {
    border: 0;
    padding: 15px;
  }

}
